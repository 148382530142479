import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'app-alert-snackbar',
  templateUrl: './alert-snackbar.component.html',
  styleUrls: ['./alert-snackbar.component.css']
})

export class AlertSnackBarComponent {
  motivo: string;

  constructor(public snackBarRef: MatSnackBarRef<AlertSnackBarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data) {
    // console.log('data', this.data);
  }

}
