import { IdDom } from "./iddom.model";

export class Domicilio {
  id: IdDom;
  cdgpai: string = null;
  cdgef: string= null;
  cdgmu:  string= null;
  cdglo: string= null;
  cdgcol:  string= null;
  calle:  string= null;
  entrecalles:  string= null;
  cdgcl:  string= null;
  cdgpe:  string= null;
  cdgpi:  string= null;
  cdgac:  string= null;
  tipo:  string= null;
  nombre:  string= null;
  comentario: string= null;
  verificado:  string= null;
  verificape:  string= null;
  clns:  string= null;
  cdgns:  string= null;
  propiedad:  string= null;
  vigencia:  string= null;
  inicio:  string= null;
  ambulante:  string= null;
  cdginv:  string= null;
  noext:  string= null;
  noint:  string= null;
  freside:  string= null;
  cdgpif:  string= null;
  cdgfin:  string= null;
  nuevo: boolean;
}
