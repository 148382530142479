import { CompaniaMCO } from './companiamco.model';
import { IdMedioComunicacion } from "./idmediocomunicacion.model";

export class MedioComunicacion {

    id :IdMedioComunicacion;
    /*cdgac: string = null;*/
    cdgdom: string = null;
    cdginv: string = null;
    //cdgns: string = null;
    //cdgpi: string = null;
    vigencia: string = null;
    //cdgcl: string = null;
    //cdgpe: string = null;
    clns: string = null;
    comentario: string = null;
    numero: string;
    origendom: string = 'NO';
    pertenece = 'P';
    cdgctc:string=null;
    prioridad: string=null;
    compania: CompaniaMCO;
    tipo: string;
    verificado: string = null;
    verificape: string = null;
    cdgfin:string;
    cdgpe:string;
    nuevo:boolean;
}